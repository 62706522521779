import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'sortForm',
    'optionCheckbox',
  ]

  connect() {
    this.submitted = false
  }

  selectAllNone(event) {
    this.optionCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked
    })
  }

  submitForm(event) {
    event.preventDefault()
    if (this.submitted) {
      return false
    }
    event.target.classList.add('cursor-wait')
    this.sortFormTarget.submit()
    this.submitted = true
    event.target.value = 'Please wait...'
  }
}
