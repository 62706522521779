import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {

    connect() {
      this.selectedTrigger = null
  }

    select(event) {
      if (this.selectedTrigger) {
        this.selectedTrigger.closest('.accordion').querySelector('.collapse-content').classList.add('hidden')
      }
    if (this.selectedTrigger === event.target) {
      this.selectedTrigger.closest('.accordion').querySelector('.collapse-content').classList.add('hidden')
      this.selectedTrigger = null
      return
    }
    this.selectedTrigger = event.target
    this.selectedTrigger.closest('.accordion').querySelector('.collapse-content').classList.remove('hidden')
  }
}
