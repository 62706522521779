import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="responsive-menu"
export default class extends Controller {
  static targets = [
    'leftNav',
    'hamburgerIcon',
    'closeIcon',
  ]

  connect() {
    this.menuOpen = false
  }

  toggleMenu(e) {
    this.menuOpen = !this.menuOpen
    if (this.menuOpen) {
      this.closeIconTarget.classList.remove('hidden')
      this.hamburgerIconTarget.classList.add('hidden')
      this.leftNavTarget.classList.remove('hidden')
    } else {
      this.closeIconTarget.classList.add('hidden')
      this.hamburgerIconTarget.classList.remove('hidden')
      this.leftNavTarget.classList.add('hidden')
    }
  }
}
