import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ["div"]

  connect() {
    this.index = 0
    this.startAutoAdvance()
  }

  disconnect() {
    clearInterval(this.interval)
  }
  
  startAutoAdvance() {
    this.interval = setInterval(() => {
      this.next()
    }, 5000)
  }

  resetAutoAdvance() {
    clearInterval(this.interval)
    this.startAutoAdvance()
  } 
  
  previous() {
    if (this.index > 0) {
      this.divTargets[this.index].classList.add("hidden")
      this.index -= 1
      this.divTargets[this.index].classList.remove("hidden")
      this.resetAutoAdvance()
    }
  }

  next() {
    this.divTargets[this.index].classList.add("hidden")
    this.index = (this.index + 1) % this.divTargets.length
    this.divTargets[this.index].classList.remove("hidden")
    this.resetAutoAdvance()
  }
}