import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="version-display"
export default class extends Controller {

  connect() {
    this.selectedVersion = null
  }

  select(event) {
    if (this.selectedVersion) {
      this.selectedVersion.querySelector('.version-detail').classList.add('hidden')
      this.selectedVersion.classList.remove('bg-midnight-50')
    }
    if (this.selectedVersion === event.target.closest('.version-entry')) {
      this.selectedVersion.querySelector('.version-detail').classList.add('hidden')
      this.selectedVersion.classList.remove('bg-midnight-50')
      this.selectedVersion = null
      return
    }
    this.selectedVersion = event.target.closest('.version-entry')
    this.selectedVersion.classList.add('bg-midnight-50')
    this.selectedVersion.querySelector('.version-detail').classList.remove('hidden')
  }
}
